import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, empty, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User } from '../../_models';
import { catchError } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    signinUser(email: string, password: string): Observable<any> {

        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': `Basic ${window.btoa(environment.basicAuthUsername + ':' + environment.basicAuthPassword)}`,
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        };

        const params = new HttpParams()
            .set('grant_type', 'password')
            .set('password', password)
            .set('username', email);

        return this.http.post<any>(environment.apiURL + '/oauth/token', params, httpOptions)
            .pipe(map(user => {
                const now = new Date();
                const sessionExpiry = now.getTime() + user["expires_in"];

                // store user details and jwt token in local storage to keep user logged in between page refreshes
                sessionStorage.setItem('currentUser', JSON.stringify(user));
                sessionStorage.setItem('sessionExpiry', sessionExpiry);
                this.currentUserSubject.next(user);
                return user;
            }),

                catchError((err, caught) => {
                    return throwError(err);
                })
            );
    }

    signoutUser() {
        // remove user from local storage to log user out
        sessionStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }

    isAuthenticated() {
        return this.currentUserSubject?.value != null;
    }

}