// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
//  basePath: '',

export const environment = {
  production: false,
  apiURL: 'https://backofficeqa.gospara.com/backoffice',
  // apiURL: 'http://localhost:10001',
  // basePath: 'http://localhost:10001/api',
  basePath: 'https://backofficeqa.gospara.com/backoffice/api',
  basicAuthUsername: 'cb_colooc',
  basicAuthPassword: 'letmein_colly'
};
